<template>
  <div class="appeal">
    <div class="main">
      <div class="title">冻结原因：</div>
      <div class="txt">您商户涉嫌发布商品违规操作，已将商户冻结</div>
    </div>
    <div class="main">
      <div class="title">申诉说明：</div>
      <div class="txt1">
        <v-input placeholder="请填写申诉说明" v-model="form.txt" />
      </div>
    </div>
    <div class="main">
      <div class="title">上传照片：</div>
      <div class="txt2">
        <v-upload
          class="oItem-b_upload"
          :imgUrl.sync="form.proposalPic1"
          :activityPicture.sync="form.proposalPic"
          :maxCount="2"
          ref="load"
        ></v-upload>
      </div>
    </div>
    <div class="btn">提交</div>
  </div>
</template>

<script>
import { getBusinessCircleStatusUrl, getMyBusinessUrl } from "./api.js";

var moment = require("moment");
export default {
  name: "businessCircle",
  data() {
    return {
      form: {
        txt: "",
        proposalPic: "",
      },
    };
  },
  created() {},
  computed: {
    userId() {
      return this.$store.state.userId;
    },
  },
  destroyed() {},
  methods: {},
};
</script>
<style lang="less" scoped>
.appeal {
  background: rgba(241, 241, 241, 0.85);
  min-height: 100vh;
  padding: 10px 52px;
  box-sizing: border-box;
  .main {
    .title {
      font-size: 32px;
      font-weight: 400;
      color: #333333;
      line-height: 44px;
      margin: 52px 0 18px;
    }
    .txt {
      padding: 22px 40px 180px;
      background: rgba(214, 214, 214, 0.85);
      border-radius: 16px;
      font-size: 28px;
    }
    .txt1 {
      /deep/ .van-field {
        background: rgba(241, 241, 241, 0.85);
        padding: 22px 40px 186px;
        box-sizing: border-box;
        font-size: 28px;
        font-weight: 400;
        color: rgba(0, 0, 0, 0.5);
        line-height: 60px;
      }
    }
  }
  .btn {
    width: 550px;
    height: 66px;
    background: linear-gradient(180deg, #ffc055 0%, #fe9514 100%);
    border-radius: 10px;
    font-size: 32px;
    font-weight: 600;
    color: #ffffff;
    line-height: 66px;
    text-align: center;
    margin: 110px auto 0;
  }
}
</style>
